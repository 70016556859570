import styled from 'styled-components'
import { Divider } from '../divider/Divider'
import { Button } from '../button/Button'
import { LoginForm } from './LoginForm'
import { Link } from 'react-router-dom'
import { ROUTES } from 'src/routes'
import { useError } from 'src/service/context/ErrorContext'
import GoogleLogo from 'src/assets/images/google.svg'
import { useUser } from 'src/user/UserContext'
import { useMessageSource } from 'src/i18n/useMessageSource'

export const Login = () => {
  const { AlertErrorOutlet } = useError()
  const { loginWithGoogle } = useUser()
  const { getMessage } = useMessageSource()

  const onSignInWithGoogle = async () => {
    await loginWithGoogle(false, null)
  }

  return (
    <Container>
      <Title>{getMessage('welcomeBack')}</Title>
      <SubTitle>Welcome back, please enter your details</SubTitle>
      <Button variant='transparent' onClick={onSignInWithGoogle}>
        <Image src={GoogleLogo} />
        Login with Google
      </Button>
      <Divider />
      <AlertErrorOutlet />
      <LoginForm />
      <SubTitle style={{ marginTop: 5 }}>
        Don't have an account?{' '}
        <Link to={ROUTES.RegisterPage.path} className='link'>
          Sign up now
        </Link>
      </SubTitle>
    </Container>
  )
}

const Container = styled.div`
  width: ${({ theme }) => theme.width.full};
  text-align: center;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.font.size.XXL};
  margin-bottom: 10px;
`

const SubTitle = styled.h3`
  color: ${({ theme }) => theme.color.darkGrey};
  font-size: ${({ theme }) => theme.font.size.M};
  margin-bottom: 32px;

  .link {
    color: ${({ theme }) => theme.color.primary};
    font-weight: ${({ theme }) => theme.font.weight.medium};
    text-decoration: none;
  }
`

const Image = styled.img`
  width: 20px;
  height: 20px;
`
