import { ROLE_TYPE } from 'src/routes'
import { TAccountPlan, TStatus } from './constants'
import { Timestamp } from 'firebase/firestore'
import dayjs from 'dayjs'

export const retrieveAvatarUrl = (firstName: string = 'D', lastName: string = 'I'): string => {
  return `https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=0C2440&color=E0E2E7&size=512`
}

export const retrieveDisplayName = (firstName?: string, lastName?: string): string => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }
  return `Digitalics Innovation User`
}

export const resolvePlan = (plan: TAccountPlan): string => {
  switch (plan) {
    case 'FREE':
      return 'Free'
    case 'BUSINESS':
      return 'Business'
    default:
      return 'n/a'
  }
}

export const resolveRole = (role: ROLE_TYPE): string => {
  switch (role) {
    case 'ADMIN':
      return 'Admin'
    case 'MEMBER':
      return 'Member'
    default:
      return 'n/a'
  }
}

export const resolveStatus = (status: TStatus): { label: string; color: string } => {
  switch (status) {
    case 'NEW':
      return {
        label: 'New',
        color: '#0C2440'
      }
    case 'NOT_STARTED':
      return {
        label: 'Not started',
        color: '#667085'
      }
    case 'IN_PROGRESS':
      return {
        label: 'In progress',
        color: '#F86624'
      }
    case 'PENDING':
      return {
        label: 'Pending',
        color: '#F86624'
      }
    case 'IMPORTED':
      return {
        label: 'Imported',
        color: '#1A9882'
      }
    case 'CONTACTED':
      return {
        label: 'Contacted',
        color: '#2BB2FE'
      }
    case 'UNFINISHED':
      return {
        label: 'Unfinished',
        color: '#EB3D4D'
      }
    case 'FINISHED':
      return {
        label: 'Finished',
        color: '#1A9882'
      }
    case 'ERROR':
      return {
        label: 'Error',
        color: '#EB3D4D'
      }
    default:
      return {
        label: 'n/a',
        color: '#0C2440'
      }
  }
}

export const resolveFirebaseDateToDate = (timestamp: Timestamp): Date => {
  return new Date(timestamp.seconds * 1000)
}

export const getFirebaseFormattedDateAndTime = (timestamp: Timestamp): string => {
  const date = resolveFirebaseDateToDate(timestamp)
  const dayjsDate = dayjs(date)
  return dayjsDate.format('DD MMM YYYY - HH:mm')
}

// Helper function to convert hex to rgb
export const hexToRgb = (hex: string) => {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '')

  // Parse r, g, b values
  let bigint = parseInt(hex, 16)
  let r = (bigint >> 16) & 255
  let g = (bigint >> 8) & 255
  let b = bigint & 255

  return `${r}, ${g}, ${b}`
}
