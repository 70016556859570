import { createColumnHelper } from '@tanstack/react-table'
import { Timestamp } from 'firebase/firestore'
import { useMemo } from 'react'
import { Card } from 'src/components/card/Card'
import { PageLayout } from 'src/components/page-layout/PageLayout'
import { ProgressChip } from 'src/components/progress-chip/ProgressChip'
import { StatisticCard } from 'src/components/statistic-card/StatisticCard'
import { StatusChip } from 'src/components/status-chip/StatusChip'
import { Table } from 'src/components/table/Table'
import { STATUS, TStatus } from 'src/shared/constants'
import { StatisticFinished, StatisticImported, StatisticInProgress, StatisticPaused } from 'src/shared/icons'
import { resolveFirebaseDateToDate, resolveStatus } from 'src/shared/utils'
import { styled } from 'styled-components'

interface Stats {
  campaign_name: string
  progress: number
  due_date: Timestamp
  status: TStatus
}

const MOCK_STATS: Stats[] = [
  {
    campaign_name: 'Campaign 1',
    progress: 80,
    due_date: Timestamp.now(),
    status: STATUS.IN_PROGRESS
  },
  {
    campaign_name: 'Campaign 12',
    progress: 60,
    due_date: Timestamp.now(),
    status: STATUS.IN_PROGRESS
  },
  {
    campaign_name: 'Campaign 123',
    progress: 49,
    due_date: Timestamp.now(),
    status: STATUS.IN_PROGRESS
  },
  {
    campaign_name: 'Campaign 154',
    progress: 100,
    due_date: Timestamp.now(),
    status: STATUS.FINISHED
  },
  {
    campaign_name: 'Campaign 22',
    progress: 20,
    due_date: Timestamp.now(),
    status: STATUS.ERROR
  }
]

export const DashboardPage = () => {
  const columnHelper = createColumnHelper<Stats>()

  const stats = useMemo(() => MOCK_STATS, [])

  const columns = [
    columnHelper.accessor('campaign_name', {
      header: 'Campaign name'
    }),
    columnHelper.accessor('progress', {
      header: 'Progress',
      cell: (value) => {
        const { status, progress } = value.row.original
        const { color } = resolveStatus(status)

        return <ProgressChip value={progress} color={color} />
      }
    }),
    columnHelper.accessor('due_date', {
      header: 'Due date',
      cell: (due_date) => resolveFirebaseDateToDate(due_date.getValue()).toDateString()
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (status) => {
        const { label, color } = resolveStatus(status.getValue())

        return <StatusChip label={label} color={color} />
      }
    })
  ]

  return (
    <PageLayout title='Campaign stats'>
      <StatisticRow>
        <StatisticCard label='Completed campaigns' value='4,412' icon={StatisticFinished} />
        <StatisticCard label='In-progress campaigns' value='1,920' icon={StatisticInProgress} />
        <StatisticCard label='Paused campaigns' value='6,784' icon={StatisticPaused} />
        <StatisticCard label='Imported profiles' value='329' icon={StatisticImported} />
      </StatisticRow>
      <StatisticRow>
        <Card title='Revenue' description='Your revenue this year' style={{ flex: 2 }}>
          <>Hubspot iframe</>
        </Card>
        <Card title='Campaigns' description='Based on status' style={{ flex: 1 }}>
          <>Hubspot iframe</>
        </Card>
      </StatisticRow>
      <StatsContainer>
        <h3>Stats</h3>
        <Table data={stats} columns={columns} defaultSort={[{ id: 'campaign_name', desc: false }]} />
      </StatsContainer>
    </PageLayout>
  )
}

const StatisticRow = styled.div`
  width: ${({ theme }) => theme.width.full};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
`

const StatsContainer = styled.div`
  & > h3 {
    font-size: ${({ theme }) => theme.font.size.XXL};
    font-weight: ${({ theme }) => theme.font.weight.medium};
    color: ${({ theme }) => theme.color.neutralBlack};
    margin-bottom: 24px;
  }
`
