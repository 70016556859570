import { createColumnHelper } from '@tanstack/react-table'
import { useCallback, useEffect, useState } from 'react'
import { ConfirmationModal } from 'src/components/confirmation-modal/ConfirmationModal'
import { Icon } from 'src/components/icon/Icon'
import { PageLayout } from 'src/components/page-layout/PageLayout'
import { Table } from 'src/components/table/Table'
import { IUserData, useUserData } from 'src/service/user/useUserData'
import { DeleteIcon } from 'src/shared/icons'
import { resolvePlan } from 'src/shared/utils'
import { useUser } from 'src/user/UserContext'

export const TeamPage = () => {
  const { user } = useUser()
  const { getUsers, removeUserFromTeam } = useUserData()
  const columnHelper = createColumnHelper<IUserData>()

  const [users, setUsers] = useState<IUserData[]>([])
  const [userDeleteModal, setUserDeleteModal] = useState<boolean>(false)
  const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null)

  const fetchUsers = useCallback(async () => {
    const data = await getUsers()
    if (data && user) {
      setUsers(data.filter((u) => u?.team?.id === user?.team?.id))
    }
  }, [getUsers, user])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  const onDeleteUser = (id: string) => {
    setUserIdToDelete(id)
    setUserDeleteModal(true)
  }

  const onConfirmDeletion = async () => {
    if (userIdToDelete) {
      await removeUserFromTeam(userIdToDelete)
      setUserIdToDelete(null)
      setUserDeleteModal(false)
      fetchUsers()
    }
  }

  const onCancelDeletion = () => {
    setUserIdToDelete(null)
    setUserDeleteModal(false)
  }

  const columns = [
    columnHelper.accessor('firstName', {
      header: 'First name'
    }),
    columnHelper.accessor('lastName', {
      header: 'Last name'
    }),
    columnHelper.accessor('email', {
      header: 'Email'
    }),
    columnHelper.accessor('plan', {
      header: 'Plan',
      cell: (plan) => resolvePlan(plan.getValue())
    }),
    columnHelper.accessor('id', {
      header: 'Actions',
      cell: ({ row }) => {
        const { id, team } = row.original

        return (
          ((user?.id === id && user.team.invited) || (!user?.team.invited && team.invited)) && (
            <div style={{ display: 'flex', gap: 10 }}>
              <Icon size={18} icon={DeleteIcon} onClick={() => onDeleteUser(id)} />
            </div>
          )
        )
      },
      enableSorting: false
    })
  ]

  return (
    <PageLayout title='Users'>
      <>
        <Table data={users} columns={columns} defaultSort={[{ id: 'firstName', desc: false }]} />
        <ConfirmationModal
          title='Remove User'
          message='Are you sure you want to remove this user from the team?'
          confirmButtonText='Remove'
          visible={userDeleteModal}
          onClose={onCancelDeletion}
          onConfirm={onConfirmDeletion}
        />
      </>
    </PageLayout>
  )
}
