import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

interface IPageLayout {
  title: string
  children: ReactNode
  action?: ReactNode
  breadcrumbs?: {
    label: string
    url?: string
  }[]
}

export const PageLayout: FC<IPageLayout> = ({ title, children, action, breadcrumbs }) => {
  const navigate = useNavigate()

  const onNavigate = (url?: string) => {
    navigate(url ?? '')
  }

  return (
    <Container>
      <Row>
        <div>
          <Title>{title}</Title>
          <div>
            {breadcrumbs?.map((b) => (
              <>
                {b?.url ? (
                  <strong style={{ cursor: 'pointer' }} onClick={() => onNavigate(b.url)}>
                    {b.label} &gt;{' '}
                  </strong>
                ) : (
                  b.label
                )}
              </>
            ))}
          </div>
        </div>
        <div>{action}</div>
      </Row>
      <Content>{children}</Content>
    </Container>
  )
}

const Container = styled.div`
  width: ${({ theme }) => theme.width.full};
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.size.XXL};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  color: ${({ theme }) => theme.color.neutralBlack};
`

const Content = styled.div`
  margin-top: 24px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`
