import { FC } from 'react'
import styled from 'styled-components'

interface IProgressChip {
  value: number
  color: string
}

export const ProgressChip: FC<IProgressChip> = ({ value, color }) => {
  return (
    <Container>
      <ProgressContainer>
        <Progress $color={color} $value={value} />
      </ProgressContainer>
      <p>{value} %</p>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
`

const ProgressContainer = styled.div`
  width: 65px;
  height: 8px;
  border-radius: 10px;
  background: #e0e2e7;
`

const Progress = styled.div<{ $color: string; $value: number }>`
  width: ${({ $value }) => `${$value}%`};
  background: ${({ $color }) => $color};
  border-radius: 10px;
  height: 8px;
`
