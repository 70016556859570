import styled from 'styled-components'
import { Divider } from '../divider/Divider'
import { Button } from '../button/Button'
import { Link, useSearchParams } from 'react-router-dom'
import { ROUTES } from 'src/routes'
import { RegisterForm } from './RegisterForm'
import { useError } from 'src/service/context/ErrorContext'
import GoogleLogo from 'src/assets/images/google.svg'
import { useUser } from 'src/user/UserContext'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { useMemo } from 'react'

export const Register = () => {
  const { AlertErrorOutlet } = useError()
  const { loginWithGoogle } = useUser()
  const { getMessage } = useMessageSource()
  const [searchParams] = useSearchParams()

  const team = useMemo(() => searchParams.get('team'), [searchParams])

  const onSignUpWithGoogle = async () => {
    await loginWithGoogle(true, team)
  }

  return (
    <Container>
      <Title>{getMessage('welcome')}</Title>
      <Button variant='transparent' onClick={onSignUpWithGoogle}>
        <Image src={GoogleLogo} />
        Sign up with Google
      </Button>
      <Divider />
      <AlertErrorOutlet />
      <RegisterForm team={team} />
      <SubTitle style={{ marginTop: 5 }}>
        Already have an account?{' '}
        <Link to={ROUTES.LoginPage.path} className='link'>
          Log in
        </Link>
      </SubTitle>
    </Container>
  )
}

const Container = styled.div`
  width: ${({ theme }) => theme.width.full};
  text-align: center;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.font.size.XXL};
  margin-bottom: 10px;
`

const SubTitle = styled.h3`
  color: ${({ theme }) => theme.color.darkGrey};
  font-size: ${({ theme }) => theme.font.size.M};
  margin-bottom: 32px;

  .link {
    color: ${({ theme }) => theme.color.primary};
    font-weight: ${({ theme }) => theme.font.weight.medium};
    text-decoration: none;
  }
`

const Image = styled.img`
  width: 20px;
  height: 20px;
`
