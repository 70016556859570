import {
  GoogleAuthProvider,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile
} from 'firebase/auth'
import { useFirebase } from '../context/FirebaseContext'
import { retrieveAvatarUrl, retrieveDisplayName } from 'src/shared/utils'
import { useError } from 'src/service/context/ErrorContext'
import { useUserData } from 'src/service/user/useUserData'

export interface IRegisterUser {
  email: string
  password: string
  firstName: string
  lastName: string
  team: string | null
}

export interface IAuthenticateUser {
  email: string
  password: string
  firstName?: string
  lastName?: string
}

export const useFirebaseAuth = () => {
  const { auth } = useFirebase()
  const { saveUser } = useUserData()
  const { handleError } = useError()

  const firebaseRegister = async (user: IRegisterUser) => {
    const { firstName, lastName, email, password, team } = user

    try {
      const userCredential = auth && (await createUserWithEmailAndPassword(auth, email, password))
      if (userCredential) {
        const photoUrl = retrieveAvatarUrl(firstName, lastName)
        await updateProfile(userCredential.user, {
          displayName: retrieveDisplayName(firstName, lastName),
          photoURL: photoUrl
        })
        await saveUser({ email, firstName, lastName, photoUrl, team }, userCredential.user.uid)
        return userCredential.user
      }
    } catch (err: any) {
      handleError(err.message)
      return null
    }
  }

  const firebaseLogin = async (user: IAuthenticateUser) => {
    const { email, password } = user

    try {
      const userCredential = auth && (await signInWithEmailAndPassword(auth, email, password))
      if (userCredential) {
        return userCredential.user
      }
    } catch (err: any) {
      handleError(err.message)
      return null
    }
  }

  const firebaseLogout = async () => {
    try {
      auth && (await signOut(auth))
    } catch (err: any) {
      handleError(err.message)
      return null
    }
  }

  const firebaseForgotPassword = async (email: string): Promise<boolean> => {
    try {
      auth && (await sendPasswordResetEmail(auth, email))
      return true
    } catch (err: any) {
      handleError(err.message)
      return false
    }
  }

  const firebaseResetPassword = async (code: string, password: string): Promise<boolean> => {
    try {
      auth && (await confirmPasswordReset(auth, code, password))
      return true
    } catch (err: any) {
      handleError(err.message)
      return false
    }
  }

  const firebaseGoogleLogin = async (register: boolean, team: string | null) => {
    try {
      if (auth) {
        const provider = new GoogleAuthProvider()

        provider.addScope('profile')
        provider.addScope('email')
        const result = await signInWithPopup(auth, provider)
        if (register) {
          const user = result.user
          const email = user.email ?? 'n/a'
          const name = user.displayName?.split(' ')
          const firstName = name?.[0] ?? ''
          const lastName = name?.[1] ?? ''
          const photoUrl = user.photoURL ?? retrieveAvatarUrl(firstName, lastName)
          saveUser({ email, firstName, lastName, photoUrl, team }, user.uid)
        }
      }
    } catch (err: any) {
      handleError(err.message)
      return null
    }
  }

  return {
    firebaseRegister,
    firebaseLogin,
    firebaseLogout,
    firebaseForgotPassword,
    firebaseResetPassword,
    firebaseGoogleLogin
  }
}
