import styled from 'styled-components'
import { Search } from '../search/Search'
import { useDelayedNavigate } from 'src/hooks/useDelayedNavigate'
import { Icon } from '../icon/Icon'
import { ArrowDownIcon } from 'src/shared/icons'
import { Avatar } from '../avatar/Avatar'
import { useUser } from 'src/user/UserContext'
import { useState } from 'react'
import { ROUTES } from 'src/routes'

const LANG_EN_FLAG = 'https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg'

export const Header = () => {
  const navigate = useDelayedNavigate()
  const { user, logout } = useUser()

  const [profileExpand, setProfileExpand] = useState<boolean>(false)

  const onSearch = (query: string) => {
    navigate(`/search/${query}`)
  }

  return (
    <Container>
      <div>
        <Search onSearch={onSearch} label='Search' />
      </div>
      <div>
        {user && (
          <Toolbar>
            <InfoSection>
              <Avatar url={LANG_EN_FLAG} size={28} />
            </InfoSection>
            <ProfileContainer>
              <ProfileSection>
                <div>
                  <Avatar url={user.photoUrl} />
                </div>
                <UserDetails>
                  <h1 className='user-name'>{`${user.firstName} ${user.lastName}`}</h1>
                  <p className='user-plan'>{user.plan.toLowerCase()} plan</p>
                </UserDetails>
                <div>
                  <Icon icon={ArrowDownIcon} onClick={() => setProfileExpand(!profileExpand)} />
                </div>
              </ProfileSection>
              <ProfileOptions $show={profileExpand}>
                <ProfileOptionItem onClick={() => navigate(ROUTES.ProfilePage.path)}>Profile</ProfileOptionItem>
                <ProfileOptionItem $last onClick={() => logout()}>
                  Logout
                </ProfileOptionItem>
              </ProfileOptions>
            </ProfileContainer>
          </Toolbar>
        )}
      </div>
    </Container>
  )
}

const Container = styled.div`
  padding: 12px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const InfoSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-right: 8px;
`

const ProfileSection = styled.div`
  border-left: 1px solid ${({ theme }) => theme.color.lightGrey};
  margin-left: 10px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .user-name {
    font-size: ${({ theme }) => theme.font.size.S};
    font-weight: ${({ theme }) => theme.font.weight.medium};
    color: ${({ theme }) => theme.color.neutralBlack};
  }

  .user-plan {
    font-size: ${({ theme }) => theme.font.size.XS};
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: #4a4c56;
    text-transform: capitalize;
  }
`

const ProfileContainer = styled.div`
  position: relative;
  display: inline;
  z-index: 1;
`

const ProfileOptions = styled.div<{ $show: boolean }>`
  position: absolute;
  display: ${({ $show }) => ($show ? 'block' : 'none')};
  background-color: white;
  z-index: 999;
  min-width: 150px;
  top: 35px;
  right: 0;
  padding: 6px 0;
  border-radius: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
`

const ProfileOptionItem = styled.div<{ $last?: boolean }>`
  padding: 8px;
  border-bottom: ${({ $last, theme }) => ($last ? '0' : `1px solid ${theme.color.lightGrey}`)};
  cursor: pointer;
  text-align: left;
`
