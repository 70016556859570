import styled, { css } from 'styled-components'
import logo from 'src/assets/images/logo.svg'
import { Chip } from '../chip/Chip'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES, isPathAllowedForRole } from 'src/routes'
import { devices } from 'src/theme/theme'
import { Icon } from '../icon/Icon'
import { useUser } from 'src/user/UserContext'
import { IUserData, useUserData } from 'src/service/user/useUserData'
import { useCallback, useEffect, useState } from 'react'

export const SIDEBAR_WIDTH = 300

export const Sidebar = () => {
  const { user, setupStats } = useUser()
  const { getUsers } = useUserData()
  const navigate = useNavigate()
  const location = useLocation()
  const [users, setUsers] = useState<IUserData[]>([])

  const fetchUsers = useCallback(async () => {
    const data = await getUsers()
    if (data && user) {
      setUsers(data.filter((u) => u?.team?.id === user?.team?.id))
    }
  }, [getUsers, user])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  const roles = user!.roles

  const menuItems = Object.values(ROUTES)
    .filter(({ roles, label, icon }) => roles && label && icon)
    .filter(({ path }) => isPathAllowedForRole(path, roles))

  const resolveActive = (path: string): boolean => {
    return location.pathname.startsWith(path)
  }

  const resolveChipStatus = (path: string) => {
    if (path === ROUTES.SetupPage.path) {
      if (setupStats.some((s) => s.done === false)) {
        return <Chip label='In progress' size='small' />
      }
      return <Chip label='Done' size='small' />
    }
    return null
  }

  return (
    <Container>
      <LogoImage src={logo} alt='digitalics-innovation-logo' />
      <MenuList>
        {menuItems.map(({ label, path, icon }) => {
          if (path === '/team' && users.length === 1) {
            return null
          }
          return (
            <MenuItem key={path} $active={resolveActive(path)} onClick={() => navigate(path)}>
              <div className='menu-item-label'>
                <Icon icon={icon!} size={24} style={{ marginRight: 5 }} />
                <Label>{label}</Label>
              </div>
              {resolveChipStatus(path)}
            </MenuItem>
          )
        })}
      </MenuList>
    </Container>
  )
}

const Container = styled.div`
  width: ${SIDEBAR_WIDTH}px;
  height: auto;
  min-height: ${({ theme }) => theme.height.screen};
  background: #fff;
  text-align: center;

  @media ${devices.mobile} {
    display: none;
  }
`
const LogoImage = styled.img`
  width: 200px;
  margin: 24px;
`

const MenuList = styled.div`
  text-align: left;
`

const MenuItem = styled.div<{ $active: boolean }>`
  padding: 14px 24px;
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 6px solid transparent;
  cursor: pointer;
  transition: 0.3s;

  ${({ $active }) =>
    $active &&
    css`
      background: ${({ theme }) => theme.color.secondary};
      border-left: 6px solid ${({ theme }) => theme.color.primary};
    `};

  &:hover {
    background: ${({ theme }) => theme.color.secondary};
    border-left: 6px solid ${({ theme }) => theme.color.primary};
  }

  .menu-item-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

const Label = styled.p`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.font.size.L};
  font-weight: ${({ theme }) => theme.font.weight.medium};
`
