import { FC } from 'react'
import { hexToRgb } from 'src/shared/utils'
import styled from 'styled-components'

interface IStatusChip {
  label: string
  color: string
}

export const StatusChip: FC<IStatusChip> = ({ label, color }) => {
  return <Container $color={color}>{label}</Container>
}

const Container = styled.div<{ $color: string }>`
  width: auto;
  display: inline;
  padding: 4px 10px;
  border-radius: 8px;
  color: ${({ $color }) => $color};
  background: ${({ $color }) => `rgba(${hexToRgb($color)}, 0.1)`};
`
