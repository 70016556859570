import { useField } from 'formik'
import { FC, InputHTMLAttributes, ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  additionalLabel?: ReactNode
  name: string
  placeholder?: string
  value?: string
  validate?: (value: any) => undefined | string | Promise<any>
  postfixIcon?: ReactNode
  variant?: 'small'
}

export const InputField: FC<IInput> = ({
  label,
  additionalLabel,
  name,
  disabled,
  placeholder,
  postfixIcon,
  value,
  onChange,
  variant,
  ...props
}) => {
  const [field, meta, helpers] = useField({ name, ...props })
  const [localValue, setLocalValue] = useState<string>(field.value ?? '')

  const onLocalChange = (e: any) => {
    const value = e?.target?.value
    setLocalValue(value)
    helpers.setValue(value)
  }

  const LabelComponent = () => {
    return (
      <Label htmlFor={name} $disabled={disabled} $size={variant}>
        {label}
      </Label>
    )
  }

  return (
    <Container $size={variant}>
      {additionalLabel && (
        <Row>
          {label && <LabelComponent />}
          {additionalLabel}
        </Row>
      )}
      {label && !additionalLabel && <LabelComponent />}
      <Wrapper>
        <Input
          {...field}
          placeholder={placeholder}
          value={localValue}
          onChange={onLocalChange}
          disabled={disabled}
          $size={variant}
          {...props}
        />
        {postfixIcon}
      </Wrapper>
      {meta.touched && meta.error ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    </Container>
  )
}

const Container = styled.div<{ $size?: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.width.full};
  text-align: left;
  margin: 16px 0;

  ${({ $size }) =>
    $size === 'small' &&
    css`
      margin: 10px 0;
    `}
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Label = styled.label<{ $disabled?: boolean; $size?: string }>`
  margin-bottom: 0.5rem;
  color: ${({ $disabled, theme }) => ($disabled ? theme.color.lightGrey : theme.color.grey)};

  ${({ $size }) =>
    $size === 'small' &&
    css`
      font-size: ${({ theme }) => theme.font.size.S};
    `}
`

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.lightGrey};
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: ${({ theme }) => theme.width.full};
  background: ${({ theme }) => theme.color.white};
  padding-right: 12px;

  &:has(input:disabled) {
    border: 1px solid #c5c5c5;
    background: ${({ theme }) => theme.color.white};
  }

  &:has(input:focus) {
    outline: none;
    border: 1px solid ${({ theme }) => theme.color.primary};
    background: ${({ theme }) => theme.color.white};
  }
`

const Input = styled.input<{ $size?: string }>`
  border: none;
  box-shadow: none;
  outline: none;
  font-size: ${({ theme }) => theme.font.size.M};
  line-height: 1.2;
  flex-grow: 1;
  height: 2.5rem;
  padding: 0 1rem;
  border-radius: 10px;

  ${({ $size }) =>
    $size === 'small' &&
    css`
      height: 2rem;
      line-height: 1;
      font-size: ${({ theme }) => theme.font.size.S};
    `}

  &::placeholder,
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.color.darkGrey};
  }
`
const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.color.danger};
  font-size: ${({ theme }) => theme.font.size.XS};
  margin-top: 5px;
`
