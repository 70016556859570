import { ACCOUNT_PLAN, COLLECTION, TAccountPlan, TSetupType, defaultSetupStats } from 'src/shared/constants'
import { useFirebaseData } from '../firebase/hooks/useFirebaseData'
import { ROLES, ROLE_TYPE } from 'src/routes'
import { LOCALE, TLocale } from 'src/i18n/LocalizationProvider'
import { useCallback, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

export interface ISetupStat {
  type: TSetupType
  done: boolean
}

export interface IUserTeam {
  id: string
  invited: boolean
}

export interface IUserData {
  id: string
  firstName: string
  lastName: string
  email: string
  plan: TAccountPlan
  roles: ROLE_TYPE[]
  walkthrough: boolean
  setupStats: ISetupStat[]
  language: TLocale
  team: IUserTeam
  photoUrl: string
  stripe_id: string | null
  stripe_plan: string | null
}

interface ISaveUserData {
  firstName: string
  lastName: string
  email: string
  photoUrl: string
  team: string | null
}

export const useUserData = () => {
  const { getAll, get, store, update, destroy, sendEmail } = useFirebaseData(COLLECTION.USERS)

  const defaultTeam = useMemo(
    () => ({
      team: {
        id: uuidv4(),
        invited: false
      }
    }),
    []
  )

  const defaultUserData = useMemo(
    () => ({
      id: '',
      plan: ACCOUNT_PLAN.FREE,
      roles: [ROLES.MEMBER],
      walkthrough: false,
      setupStats: defaultSetupStats,
      language: LOCALE.EN,
      stripe_id: null,
      stripe_plan: null
    }),
    []
  )

  const getUsers = useCallback(async () => {
    return await getAll<IUserData>()
  }, [getAll])

  const getUser = useCallback(
    async (id: string) => {
      return await get<IUserData>(id)
    },
    [get]
  )

  const saveUser = useCallback(
    async (data: ISaveUserData, uid: string) => {
      return await store<IUserData>(
        {
          ...defaultUserData,
          ...data,
          ...(data.team ? { team: { id: data.team, invited: true } } : defaultTeam)
        },
        uid
      )
    },
    [store, defaultUserData, defaultTeam]
  )

  const updateUser = useCallback(
    async (id: string, data: Partial<IUserData>) => {
      return await update<Partial<IUserData>>(id, data)
    },
    [update]
  )

  const removeUser = useCallback(
    async (id: string) => {
      return await destroy(id)
    },
    [destroy]
  )

  const removeUserFromTeam = useCallback(
    async (id: string) => {
      return await update<Partial<IUserData>>(id, { team: { id: uuidv4(), invited: false } })
    },
    [update]
  )

  const inviteUser = useCallback(
    async (to: string, subject: string, html: string) => {
      return await sendEmail(to, subject, html)
    },
    [sendEmail]
  )

  return {
    getUsers,
    getUser,
    saveUser,
    updateUser,
    removeUser,
    removeUserFromTeam,
    inviteUser
  }
}
