import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { Button } from 'src/components/button/Button'
import { InputField } from 'src/components/input/Input'
import { Modal } from 'src/components/modal/Modal'
import { useLeadListData } from 'src/service/lead-list/useLeadListData'
import { useUser } from 'src/user/UserContext'
import { styled } from 'styled-components'
import * as Yup from 'yup'

interface ICreateLeadListModal {
  visible: boolean
  onClose: () => void
  onSuccess: () => void
}

interface INewLeadListForm {
  name: string
  team: string
}

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required')
})

export const NewLeadListModal: FC<ICreateLeadListModal> = ({ visible, onClose, onSuccess }) => {
  const { user } = useUser()
  const { saveLeadList } = useLeadListData()
  const [initialValues, setInitialValues] = useState<INewLeadListForm>({ name: '', team: '' })

  const onSubmit = async (values: INewLeadListForm) => {
    const teamId = user?.team.id ?? ''
    const success = await saveLeadList({ ...values, team: teamId })
    if (success) {
      setInitialValues(values)
      onSuccess()
    }
  }

  return (
    <Modal visible={visible} size='small' onClose={onClose} shouldCloseOnOutsideClick={false} closeButton>
      <Title>New List</Title>
      <Formik<INewLeadListForm> initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {() => (
          <Form>
            <InputField type='text' name='name' label='Name' placeholder='Name' />
            <Button type='submit'>Create</Button>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.XL};
`
