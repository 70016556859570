import { Form, Formik } from 'formik'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'src/components/button/Button'
import { InputField } from 'src/components/input/Input'
import { useUserData } from 'src/service/user/useUserData'
import { invitationTemplate } from 'src/shared/email/invitation'
import { useUser } from 'src/user/UserContext'
import { styled } from 'styled-components'
import * as Yup from 'yup'

interface IInviteForm {
  emails: string
}

interface IEmailTemplate {
  sender_name: string
  sender_email: string
  recipient_email: string
  invitation_url: string
}

const validationSchema = Yup.object().shape({
  emails: Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value
      }
      return originalValue ? originalValue.split(/[\s,]+/) : []
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
})

export const InviteTeam = () => {
  const { user } = useUser()
  const { inviteUser } = useUserData()
  const [initialValues, setInitialValues] = useState({ emails: '' })

  const onEmailSend = async ({ sender_name, sender_email, recipient_email, invitation_url }: IEmailTemplate) => {
    const template = invitationTemplate(sender_name, sender_email, recipient_email, invitation_url)
    const success = await inviteUser(recipient_email, `You're invited to Digitalics Innovation!`, template)
    if (success) {
      setInitialValues({ emails: '' })
      toast.success('Invitations are successfully sent!')
    }
  }

  const onSubmit = async (values: IInviteForm) => {
    const { emails } = values
    if (user) {
      const emailArray = emails.replaceAll(' ', '').split(',')
      const recipients = emailArray.map((email) => ({
        sender_email: user.email,
        sender_name: `${user.firstName} ${user.lastName}`,
        recipient_email: email,
        invitation_url: `https://${window.location.host}/register?team=${user?.team.id}`
      }))

      recipients.forEach(async (r) => {
        await onEmailSend(r)
      })
    }
  }

  return (
    <Container>
      <Formik<IInviteForm>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ isSubmitting, isValid }) => (
          <Form noValidate>
            <InputField name='emails' placeholder='Insert here email addresses separated by commas' />
            <Button type='submit' disabled={!isValid || isSubmitting} variant='secondary'>
              Send Invite
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

const Container = styled.div`
  width: ${({ theme }) => theme.width.full};
`
