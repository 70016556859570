import { FC, ImgHTMLAttributes, MouseEvent } from 'react'
import styled from 'styled-components'

interface IIcon extends ImgHTMLAttributes<HTMLImageElement> {
  size?: number
  icon: string
  onClick?: (event: MouseEvent<HTMLElement>) => void
}

export const Icon: FC<IIcon> = ({ size = 24, icon, onClick, ...props }) => {
  return <Image $size={size} $clickable={Boolean(onClick)} src={icon} onClick={onClick} {...props} />
}

const Image = styled.img<{ $size: number; $clickable: boolean }>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'auto')};
`
