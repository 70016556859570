import { COLLECTION, STATUS, TStatus } from 'src/shared/constants'
import { useFirebaseData } from '../firebase/hooks/useFirebaseData'
import { Timestamp } from 'firebase/firestore'
import { useCallback, useMemo } from 'react'

export interface ILeadData {
  id: string
  lead_list_id: string
  name: string
  company: string
  email: string
  phone: string
  status: TStatus
  created_at: Timestamp
  updated_at: Timestamp
}

interface ISaveLeadData {
  lead_list_id: string
  name: string
  company: string
  email: string
  phone: string
}

export const useLeadData = () => {
  const { getAll, get, store, update, destroy } = useFirebaseData(COLLECTION.LEAD)

  const defaultLeadData = useMemo(
    () => ({
      id: '',
      status: STATUS.NEW,
      created_at: Timestamp.now(),
      updated_at: Timestamp.now()
    }),
    []
  )

  const getLeads = useCallback(async () => {
    return await getAll<ILeadData>()
  }, [getAll])

  const getLead = useCallback(
    async (id: string) => {
      return await get<ILeadData>(id)
    },
    [get]
  )

  const saveLead = useCallback(
    async (data: ISaveLeadData) => {
      return await store<ILeadData>({
        ...defaultLeadData,
        ...data
      })
    },
    [store, defaultLeadData]
  )

  const updateLead = useCallback(
    async (id: string, data: Partial<ILeadData>) => {
      return await update<Partial<ILeadData>>(id, data)
    },
    [update]
  )

  const removeLead = useCallback(
    async (id: string) => {
      return await destroy(id)
    },
    [destroy]
  )

  return {
    getLeads,
    getLead,
    saveLead,
    updateLead,
    removeLead
  }
}
