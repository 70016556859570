import { styled } from 'styled-components'
import ChromeLogo from 'src/assets/images/chrome.svg'
import { Button } from 'src/components/button/Button'
import { useEffect, useState } from 'react'

export const DownloadExtension = () => {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false)

  useEffect(() => {
    if (window.chrome && window.chrome.runtime) {
      window.chrome.runtime.sendMessage(
        'bkhbbbmmemgggcjlbfacmgkdhnjaieko',
        { type: 'CHECK_EXTENSION' },
        (response: any) => {
          if (response && response.extensionInstalled) {
            setIsExtensionInstalled(true)
          }
        }
      )
    }
  }, [])

  return (
    <DownloadSection>
      <img className='logo' src={ChromeLogo} alt='chrome-logo' />
      <Button variant='secondary'>{isExtensionInstalled ? 'Downloaded' : 'Download'}</Button>
    </DownloadSection>
  )
}

const DownloadSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    margin-right: 14px;
  }
`
