export const ACCOUNT_PLAN = {
  FREE: 'FREE',
  BUSINESS: 'BUSINESS'
} as const

export type TAccountPlan = keyof typeof ACCOUNT_PLAN

export const COLLECTION = {
  USERS: 'USERS',
  LEAD_LISTS: 'LEAD_LISTS',
  LEAD: 'LEAD'
} as const

export type TCollection = keyof typeof COLLECTION

export const STATUS = {
  NEW: 'NEW',
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  PENDING: 'PENDING',
  IMPORTED: 'IMPORTED',
  CONTACTED: 'CONTACTED',
  UNFINISHED: 'UNFINISHED',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR'
} as const

export type TStatus = keyof typeof STATUS

export const SETUP_TYPE = {
  DOWNLOAD_EXTENSION: 'DOWNLOAD_EXTENSION',
  IMPORT_LEADS: 'IMPORT_LEADS',
  INVITE_TEAM: 'INVITE_TEAM',
  FIRST_CAMPAIGN: 'FIRST_CAMPAIGN'
} as const

export type TSetupType = keyof typeof SETUP_TYPE

export const defaultSetupStats = Object.values(SETUP_TYPE).map((i) => ({ type: i, done: false }))
