import { createColumnHelper } from '@tanstack/react-table'
import { useCallback, useEffect, useState } from 'react'
import { Button } from 'src/components/button/Button'
import { ConfirmationModal } from 'src/components/confirmation-modal/ConfirmationModal'
import { Icon } from 'src/components/icon/Icon'
import { PageLayout } from 'src/components/page-layout/PageLayout'
import { StatusChip } from 'src/components/status-chip/StatusChip'
import { Table } from 'src/components/table/Table'
import { DeleteIcon, EditIcon } from 'src/shared/icons'
import { getFirebaseFormattedDateAndTime, resolveStatus } from 'src/shared/utils'
import { useUser } from 'src/user/UserContext'
import { ILeadData, useLeadData } from 'src/service/lead/useLeadData'
import { useNavigate, useParams } from 'react-router-dom'
import { ILeadListData, useLeadListData } from 'src/service/lead-list/useLeadListData'

export const LeadListDetailsPage = () => {
  const { user } = useUser()
  const { getLeads, removeLead } = useLeadData()
  const { getLeadList } = useLeadListData()
  const columnHelper = createColumnHelper<ILeadData>()
  const { leadListId } = useParams<{ leadListId: string }>()
  const navigate = useNavigate()

  const [leadList, setLeadList] = useState<ILeadListData>()
  const [leads, setLeads] = useState<ILeadData[]>([])
  const [leadDeleteModal, setLeadDeleteModal] = useState<boolean>(false)
  const [leadIdToDelete, setLeadIdToDelete] = useState<string | null>(null)

  const onRefetch = useCallback(async () => {
    if (leadListId) {
      const data = await getLeads()
      const leadListData = await getLeadList(leadListId)
      if (data && leadListData && user) {
        const filtered = data.filter((l) => l.lead_list_id === leadListId)
        setLeads(filtered)
        setLeadList(leadListData)
      }
    }
  }, [getLeads, getLeadList, user, leadListId])

  useEffect(() => {
    onRefetch()
  }, [onRefetch])

  const onEditLead = (id: string) => {
    navigate(`/leads-list/${id}/edit`)
  }

  const onDeleteLead = (id: string) => {
    setLeadIdToDelete(id)
    setLeadDeleteModal(true)
  }

  const onConfirmDeletion = async () => {
    if (leadIdToDelete) {
      await removeLead(leadIdToDelete)
      setLeadDeleteModal(false)
      setLeadIdToDelete(null)
      onRefetch()
    }
  }

  const onCancelDeletion = () => {
    setLeadIdToDelete(null)
    setLeadDeleteModal(false)
  }

  const columns = [
    columnHelper.accessor('name', {
      header: 'Name'
    }),
    columnHelper.accessor('email', {
      header: 'Email'
    }),
    columnHelper.accessor('phone', {
      header: 'Phone'
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (status) => {
        const { label, color } = resolveStatus(status.getValue())
        return <StatusChip label={label} color={color} />
      }
    }),
    columnHelper.accessor('updated_at', {
      header: 'Added',
      cell: (updated_at) => getFirebaseFormattedDateAndTime(updated_at.getValue())
    }),
    columnHelper.accessor('id', {
      header: 'Actions',
      cell: (id) => (
        <div style={{ display: 'flex', gap: 10 }}>
          <Icon size={18} icon={EditIcon} onClick={() => onEditLead(id.getValue())} />
          <Icon size={18} icon={DeleteIcon} onClick={() => onDeleteLead(id.getValue())} />
        </div>
      ),
      enableSorting: false
    })
  ]

  return (
    <>
      {leadList && leads && (
        <>
          <PageLayout
            title={leadList?.name ?? ''}
            action={
              <Button variant='secondary' onClick={() => {}}>
                <Icon icon={DeleteIcon} size={16} /> Delete List
              </Button>
            }
            breadcrumbs={[
              {
                label: 'Lead List',
                url: '/leads-list'
              },
              {
                label: leadList?.name ?? ''
              }
            ]}
          >
            <Table data={leads} columns={columns} defaultSort={[{ id: 'name', desc: false }]} />
          </PageLayout>

          <ConfirmationModal
            visible={leadDeleteModal}
            title='Delete lead'
            message='Are you sure you want to delete this lead?'
            onClose={onCancelDeletion}
            onConfirm={onConfirmDeletion}
          />
        </>
      )}
    </>
  )
}
