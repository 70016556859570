import { User } from 'firebase/auth'
import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDelayedNavigate } from 'src/hooks/useDelayedNavigate'
import { ROUTES, getPublicPaths } from 'src/routes'
import { useFirebase } from 'src/service/firebase/context/FirebaseContext'
import { IAuthenticateUser, IRegisterUser, useFirebaseAuth } from 'src/service/firebase/hooks/useFirebaseAuth'
import { ISetupStat, IUserData, useUserData } from 'src/service/user/useUserData'
import { defaultSetupStats } from 'src/shared/constants'

interface UserContextType {
  user: IUserData | null
  firebaseUser: User | null
  completedWalkthrough: boolean
  setupStats: ISetupStat[]
  register: (user: IRegisterUser) => Promise<void>
  login: (user: IAuthenticateUser) => Promise<void>
  logout: () => Promise<void>
  forgotPassword: (email: string) => Promise<boolean>
  resetPassword: (code: string, email: string) => Promise<boolean>
  loginWithGoogle: (register: boolean, team: string | null) => Promise<void>
  setCompletedWalkthrough: (value: boolean) => void
}

const initialUserState: IUserData | null = null

const UserContext = createContext<UserContextType | undefined>(undefined)

export const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { auth } = useFirebase()
  const {
    firebaseRegister,
    firebaseLogin,
    firebaseForgotPassword,
    firebaseResetPassword,
    firebaseLogout,
    firebaseGoogleLogin
  } = useFirebaseAuth()
  const { getUser, updateUser } = useUserData()
  const delayedNavigate = useDelayedNavigate()
  const navigate = useNavigate()
  const location = useLocation()

  const [user, setUser] = useState<IUserData | null>(initialUserState)
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null)
  const [localLoading, setLocalLoading] = useState<boolean>(true)
  const [completedWalkthrough, setCompletedWalkthrough] = useState<boolean>(false)
  const [setupStats, setSetupStats] = useState<ISetupStat[]>([])

  useEffect(() => {
    if (auth) {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
          setFirebaseUser(user)
          const userData = await getUser(user.uid)
          if (userData) {
            setUser(userData)
            setCompletedWalkthrough(userData.walkthrough)
            if (!userData.setupStats) {
              await updateUser(userData.id, { setupStats: defaultSetupStats })
            } else {
              setSetupStats(userData.setupStats)
            }
          }
          setLocalLoading(false)
        } else {
          setFirebaseUser(null)
          setUser(null)
          setLocalLoading(false)
        }
      })

      return () => unsubscribe()
    }
  }, [auth])

  const checkPublicRoute = useCallback((route: string) => {
    return getPublicPaths().some((r) => {
      if (r === '/') {
        if (route === '/') return true
        return false
      }
      return route.includes(r)
    })
  }, [])

  const routeScheduler = useCallback(
    (route: string) => {
      const isPublicRoute = checkPublicRoute(route)

      if (!localLoading) {
        if (user && isPublicRoute) {
          navigate(ROUTES.SetupPage.path)
        } else if (!user && !isPublicRoute) {
          navigate(ROUTES.LoginPage.path)
        }
      }
    },
    [localLoading, user, navigate, checkPublicRoute]
  )

  useEffect(() => {
    const route = location.pathname
    routeScheduler(route)
  }, [routeScheduler, location])

  const register = async (user: IRegisterUser) => {
    const firebaseUser = await firebaseRegister(user)

    if (firebaseUser) {
      setFirebaseUser(firebaseUser)
      const user = await getUser(firebaseUser.uid)
      if (user) {
        setUser(user)
        setCompletedWalkthrough(false)
      }
      delayedNavigate(ROUTES.SetupPage.path)
    }
  }

  const login = async (user: IAuthenticateUser) => {
    const firebaseUser = await firebaseLogin(user)

    if (firebaseUser) {
      setFirebaseUser(firebaseUser)
      const user = await getUser(firebaseUser.uid)
      if (user) {
        setUser(user)
        setCompletedWalkthrough(user.walkthrough)
      }
      delayedNavigate(ROUTES.SetupPage.path)
    }
  }

  const logout = async () => {
    await firebaseLogout()
    setFirebaseUser(null)
    setUser(null)
    delayedNavigate(ROUTES.LoginPage.path)
  }

  const forgotPassword = async (email: string) => {
    return await firebaseForgotPassword(email)
  }

  const resetPassword = async (code: string, password: string) => {
    return await firebaseResetPassword(code, password)
  }

  const loginWithGoogle = async (register: boolean, team: string | null) => {
    await firebaseGoogleLogin(register, team)
  }

  return (
    <UserContext.Provider
      value={{
        user,
        firebaseUser,
        completedWalkthrough,
        setupStats,
        register,
        login,
        logout,
        forgotPassword,
        resetPassword,
        loginWithGoogle,
        setCompletedWalkthrough
      }}
    >
      {!localLoading && children}
    </UserContext.Provider>
  )
}

export const useUser = (): UserContextType => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
